@font-face {
    font-family: 'PixelifySans-Bold';
    src: url('../../public/assets/fonts/PixelifySans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PixelifySans-Medium';
    src: url('../../public/assets/fonts/PixelifySans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PixelifySans-Regular';
    src: url('../../public/assets/fonts/PixelifySans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PixelifySans-SemiBold';
    src: url('../../public/assets/fonts/PixelifySans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
