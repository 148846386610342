@import url('./fonts.css');

body {
    background-color: #1a1a1a;
    color: white;
    font-size: 15px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.large-title-text {
    font-size: calc(1.4rem + 2vw) !important;
    font-family: 'PixelifySans-Regular', cursive !important;
}

.medium-logo-text {
    font-size: calc(1.2rem + 1.5vw) !important;
    font-family: 'PixelifySans-Regular', cursive !important;
}

.small-logo-text {
    font-size: calc(1rem + 1vw) !important;
    font-family: 'PixelifySans-Regular', cursive !important;
}

.ui.inverted.top.fixed.menu {
    background-color: #000000;
    margin: 0;
    padding: 10px;
}

.homepage-button {
    background-color: rgba(0, 0, 0, 0.7) !important; 
    color: white !important;
    transition: background-color 0.3s ease;
}

.homepage-button:hover {
    background-color: white !important;
    color: black !important;
}